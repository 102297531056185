$(document).on "remote:load", ->
  $(".remote-pagination-container").on "click", "ul.pagination li > a[data-remote='true']", (ev)->
    ev.preventDefault()
    ev.stopPropagation()
    return if $(@).parent().hasClass("disabled")
    href = $(@).attr("href")
    unless href == "#"
      $container = $(@).parents(".remote-pagination-container")
      $responseContainer = $container.find(".load-results")
      $throbber = $container.find(".fa-spin-container")
      $.ajax
        url: href
        beforeSend: ->
          # Display a loading spinner in an empty results container.
          $responseContainer.empty()
          $("html, body").animate({scrollTop: 0}, "slow")
          $throbber.removeClass("hidden")
        complete: (response) ->
          $throbber.addClass("hidden")
        success: (response) ->
          $responseContainer.html(response)
          $responseContainer.trigger("remote:load")
