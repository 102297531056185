loadAudit = ($container) ->
  $.rails.throb on, $container
  $container.load $container.data("path")

$(document).on "page:show remote:load", ->
  $("#audit-link").on "click", (ev) ->
    ev.preventDefault()
    $(@).hide()
    $(".audit-container").each ->
      loadAudit $(@)
