updateCalendarButton = (el)->
  $(".zabuto_calendar .active").removeClass("active")
  el.addClass("active")
  date = $(el).data("date")
  $.ajax
    url: window.location.href + "?date=#{date}"
    dataType: "script"

$(document).on "page:show remote:load", ->
  $("#crew-sheet-calendar").zabuto_calendar
    language: "en"
    show_previous: true
    show_next: true
    action: -> updateCalendarButton $(@)
