$(document).on("page:show show.bs.modal remote:load", function() {
  bindFormElements();
});

function bindFormElements() {
  // bind to submit forms
  bindDataFormAttribute();

  $(".js-daterange").daterangepicker({
    ranges: {
      Today: [window.moment(), window.moment()],
      "Next 7 days": [window.moment(), window.moment().add("days", 6)],
      "Next 14 days": [window.moment(), window.moment().add("days", 13)],
      "Next 30 days": [window.moment(), window.moment().add("days", 29)],
      Yesterday: [window.moment().subtract("days", 1), window.moment().subtract("days", 1)],
      "Last 7 Days": [window.moment().subtract("days", 6), window.moment()],
      "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
      "Last Month": [window.moment().subtract("month", 1).startOf("month"), window.moment().subtract("month", 1).endOf("month")]
    },
    startDate: window.moment($(".js-daterange").data("from"), "DD-MM-YYYY"),
    endDate: window.moment($(".js-daterange").data("to"), "DD-MM-YYYY"),
    locale: { format: "DD MMM YYYY" },
    autoUpdateInput: false
  });

  $(".js-daterange").on("apply.daterangepicker", function(ev, picker) {
    $(this).val(picker.startDate.format("DD MMM YYYY") + " - " + picker.endDate.format("DD MMM YYYY"));
  });

  $(".js-timepicker").each(function() {
    $(this).val($(this).val().substring(0, 5));
  });
  $(".js-timepicker").clockpicker(
    { autoclose: true }
  );

  // BL-205
  $(document).on("select2-focus", function() { $(".popover").hide(); });
}

function bindDataFormAttribute() {
  $("*[data-form]").unbind("click");
  $("*[data-form]").on("click", function(event) {
    event.stopPropagation();
    const form = $("#" + $(this).data("form"));
    form.submit();
  });
}
