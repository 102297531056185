/**
 * File generated by js-routes 2.2.4
 * Based on Rails 6.1.6.1 routes of WrSkillstreamBluelights::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /notifications/acknowledge_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acknowledge_all_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[6,"acknowledge_all"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications/:id/acknowledge(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const acknowledge_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"acknowledge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/shifts/:id/action(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const action_candidate_shift_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/ccc_vacancies/:id/action(.:format)
 * @param {any} ccc_candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const action_ccc_candidate_ccc_vacancy_path = __jsr.r({"ccc_candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"ccc_vacancies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /active_storage/attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const active_storage_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agents/invoice_runs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_invoice_runs_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"invoice_runs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agents/invoice_runs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_invoice_runs_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"invoice_runs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas/shifts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_create_shifts_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_destroy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_show_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /agents/rotas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agent_rotors_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"agents"],[2,[7,"/"],[2,[6,"rotas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /api/grs/docs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_grs_docs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"grs"],[2,[7,"/"],[2,[6,"docs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /approvals/:approval_id/approve(.:format)
 * @param {any} approval_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_approval_path = __jsr.r({"approval_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[3,"approval_id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /areas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/vacancies/:id/assign(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_candidate_vacancy_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"vacancies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/ccc_vacancies/:id/assign(.:format)
 * @param {any} ccc_candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_ccc_candidate_ccc_vacancy_path = __jsr.r({"ccc_candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"ccc_vacancies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /approvals/:approval_id/audits(.:format)
 * @param {any} approval_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const audits_approval_path = __jsr.r({"approval_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[3,"approval_id"],[2,[7,"/"],[2,[6,"audits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/audits(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const audits_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"audits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id/audits(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const audits_ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"audits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/audits(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const audits_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"audits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /bank_holidays/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_holiday_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bank_holidays"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/bulk_update_summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_summary_timesheets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[6,"bulk_update_summary"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_timesheets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cad_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cad_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cad_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cad_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cad_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cad_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_preferences/calendar(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calendar_candidate_preferences_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_preferences"],[2,[7,"/"],[2,[6,"calendar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets/:id/cancel_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_adjustment_ccc_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/:id/cancel_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_adjustment_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id/cancel(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_memo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidate_attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidate_attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_attachments(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_candidate_attachments_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidate_credentials/:candidate_credential_id/approval(.:format)
 * @param {any} candidate_credential_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_credential_approval_path = __jsr.r({"candidate_credential_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidate_credentials"],[2,[7,"/"],[2,[3,"candidate_credential_id"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_pay_rates/:id(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_pay_rate_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_pay_rates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_pay_rates(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_pay_rates_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_pay_rates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_preferences(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_preferences_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/shift/:id/apply(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_shift_apply_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"shift"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/shift/:id/assign(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_shift_assign_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"shift"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/shift/:id/apply(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_shift_cancel_apply_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"shift"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"apply"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/shifts(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_shifts_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/vacancies(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidate_vacancies_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"vacancies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const candidates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_areas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_areas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/candidate_attachments(.:format)
 * @param {any} ccc_candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_candidate_candidate_attachments_path = __jsr.r({"ccc_candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"candidate_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/ccc_shifts(.:format)
 * @param {any} ccc_candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_candidate_ccc_shifts_path = __jsr.r({"ccc_candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"ccc_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/ccc_vacancies(.:format)
 * @param {any} ccc_candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_candidate_ccc_vacancies_path = __jsr.r({"ccc_candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"ccc_vacancies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/ccc_vacancies/:ccc_vacancy_id/ccc_vacancy_applicants(.:format)
 * @param {any} ccc_candidate_id
 * @param {any} ccc_vacancy_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_candidate_ccc_vacancy_ccc_vacancy_applicants_path = __jsr.r({"ccc_candidate_id":{"r":true},"ccc_vacancy_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"ccc_vacancies"],[2,[7,"/"],[2,[3,"ccc_vacancy_id"],[2,[7,"/"],[2,[6,"ccc_vacancy_applicants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_candidates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_invoice_runs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_invoice_run_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_invoice_runs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_invoice_runs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_invoice_runs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_invoice_runs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_numerical_adjustments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_numerical_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_numerical_adjustments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_numerical_adjustments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_numerical_adjustments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_numerical_adjustments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_shifts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_shift_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_shifts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shift_batch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_shift_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_shift_batch"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_shifts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheet_batch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_timesheet_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheet_batch"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ccc_timesheets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /charges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/areas(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_areas_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/bank_holidays(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_bank_holidays_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"bank_holidays"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/ccc_areas(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_ccc_areas_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"ccc_areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/ccc_vacancy_requirements(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_ccc_vacancy_requirements_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"ccc_vacancy_requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/:id(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_global_setting_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/grades(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_grades_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"grades"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/lists/:list_id/list_items(.:format)
 * @param {any} client_id
 * @param {any} list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_list_list_items_path = __jsr.r({"client_id":{"r":true},"list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"list_id"],[2,[7,"/"],[2,[6,"list_items"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/lists(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_lists_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/nodes(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_nodes_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"nodes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/preferred_areas(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_preferred_areas_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"preferred_areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/preferred_ccc_areas(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_preferred_ccc_areas_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"preferred_ccc_areas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/preferred_providers(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_preferred_providers_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"preferred_providers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/providers/:id(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_provider_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/providers/:provider_id/purchase_orders(.:format)
 * @param {any} client_id
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_provider_purchase_orders_path = __jsr.r({"client_id":{"r":true},"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"provider_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/providers/:provider_id/rate_card_collections(.:format)
 * @param {any} client_id
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_provider_rate_card_collections_path = __jsr.r({"client_id":{"r":true},"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"provider_id"],[2,[7,"/"],[2,[6,"rate_card_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/user_guides(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_user_guides_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"user_guides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/vacancy_requirements(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_vacancy_requirements_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"vacancy_requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/vehicle_types/:vehicle_type_id/paramedic_levels(.:format)
 * @param {any} client_id
 * @param {any} vehicle_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_vehicle_type_paramedic_levels_path = __jsr.r({"client_id":{"r":true},"vehicle_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"vehicle_types"],[2,[7,"/"],[2,[3,"vehicle_type_id"],[2,[7,"/"],[2,[6,"paramedic_levels"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/core_shifts_to_adhoc_edit(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const core_shifts_to_adhoc_edit_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"core_shifts_to_adhoc_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/core_shifts_to_adhoc_show(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const core_shifts_to_adhoc_show_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"core_shifts_to_adhoc_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/core_shifts_to_adhoc_update(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const core_shifts_to_adhoc_update_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"core_shifts_to_adhoc_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets/:id/create_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_adjustment_ccc_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/:id/create_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_adjustment_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /crew_sheets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const crew_sheets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"crew_sheets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /areas/:id/disable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /bank_holidays/:id/disable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_bank_holiday_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bank_holidays"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /list_items/:id/disable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_list_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"list_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memo_templates/:id/disable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_memo_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memo_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:id/disable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_preferred_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/disable_two_factor(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disable_two_factor_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disable_two_factor"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /approvals/:approval_id/edit(.:format)
 * @param {any} approval_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_approval_path = __jsr.r({"approval_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[3,"approval_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /areas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cad_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_cad_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cad_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_pay_rates/:id/edit(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_candidate_pay_rate_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_pay_rates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_areas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ccc_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_numerical_adjustments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ccc_numerical_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_numerical_adjustments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shifts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ccc_shift_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_shifts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ccc_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /charges/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_charge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"charges"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/:id/edit(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_client_global_setting_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/providers/:id/edit(.:format)
 * @param {any} client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_client_provider_path = __jsr.r({"client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /list_items/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_list_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"list_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_memo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memo_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_memo_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memo_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /nodes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_node_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /numerical_adjustments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_numerical_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"numerical_adjustments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /preferred_areas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_preferred_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /preferred_ccc_areas/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_preferred_ccc_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_ccc_areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /purchase_orders/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rate_card_collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rate_card_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rate_card_collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shifts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_shift_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/edit_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_status_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id/edit_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_status_ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /telephony_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_telephony_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"telephony_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /two_factor_settings/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_two_factor_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_settings"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user_guides/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_guide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_guides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /areas/:id/enable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /list_items/:id/enable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_list_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"list_items"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:id/enable(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_preferred_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /filters/:search_entity(.:format)
 * @param {any} search_entity
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filter_path = __jsr.r({"search_entity":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"filters"],[2,[7,"/"],[2,[3,"search_entity"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const filters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"filters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /passwords/first_time_access(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const first_access_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"first_time_access"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /grades(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grades_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grades"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /grs/heartbeat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grs_heartbeat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"grs"],[2,[7,"/"],[2,[6,"heartbeat"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /heartbeat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const heartbeat_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"heartbeat"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const help_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/saml/idp_sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const idp_sign_out_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"saml"],[2,[7,"/"],[2,[6,"idp_sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_runs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_run_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoice_runs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /invoice_runs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoice_runs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoice_runs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /list_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const list_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"list_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memos/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memo_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memo_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memo_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memo_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memo_templates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /memos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/saml/metadata(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metadata_user_sso_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"saml"],[2,[7,"/"],[2,[6,"metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets/:id/new_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adjustment_ccc_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"new_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/:id/new_adjustment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_adjustment_timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"new_adjustment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cad_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_cad_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cad_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_candidate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/candidate_pay_rates/new(.:format)
 * @param {any} candidate_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_candidate_pay_rate_path = __jsr.r({"candidate_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"candidate_pay_rates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_candidate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_invoice_runs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_invoice_run_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_invoice_runs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_numerical_adjustments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_numerical_adjustment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_numerical_adjustments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shifts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_shift_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_shifts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shift_batch/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_shift_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_shift_batch"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_timesheet_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_timesheet_batch/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ccc_timesheet_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_timesheet_batch"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/areas/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_area_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"areas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/bank_holidays/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_bank_holiday_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"bank_holidays"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/ccc_areas/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_ccc_area_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"ccc_areas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_global_setting_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/lists/:list_id/list_items/new(.:format)
 * @param {any} client_id
 * @param {any} list_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_list_list_item_path = __jsr.r({"client_id":{"r":true},"list_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"lists"],[2,[7,"/"],[2,[3,"list_id"],[2,[7,"/"],[2,[6,"list_items"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/nodes/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_node_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/providers/:provider_id/purchase_orders/new(.:format)
 * @param {any} client_id
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_provider_purchase_order_path = __jsr.r({"client_id":{"r":true},"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"provider_id"],[2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/providers/:provider_id/rate_card_collections/new(.:format)
 * @param {any} client_id
 * @param {any} provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_provider_rate_card_collection_path = __jsr.r({"client_id":{"r":true},"provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"providers"],[2,[7,"/"],[2,[3,"provider_id"],[2,[7,"/"],[2,[6,"rate_card_collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/user_guides/new(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_client_user_guide_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"user_guides"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memos/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_memo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memo_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_memo_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memo_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /numerical_adjustments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_numerical_adjustment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"numerical_adjustments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:preferred_provider_id/adhoc_adjustment_fees/new(.:format)
 * @param {any} preferred_provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_preferred_provider_adhoc_adjustment_fee_path = __jsr.r({"preferred_provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"preferred_provider_id"],[2,[7,"/"],[2,[6,"adhoc_adjustment_fees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:preferred_provider_id/student_add_on_charges/new(.:format)
 * @param {any} preferred_provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_preferred_provider_student_add_on_charge_path = __jsr.r({"preferred_provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"preferred_provider_id"],[2,[7,"/"],[2,[6,"student_add_on_charges"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report_definitions/:report_definition_id/reports/new(.:format)
 * @param {any} report_definition_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_definition_report_path = __jsr.r({"report_definition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report_definitions"],[2,[7,"/"],[2,[3,"report_definition_id"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shifts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_shift_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shift_batch/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_shift_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shift_batch"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /telephony_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_telephony_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"telephony_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /two_factor_settings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_two_factor_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_settings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/saml/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_sso_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"saml"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /nodes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const node_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"nodes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /numerical_adjustments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const numerical_adjustment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"numerical_adjustments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /numerical_adjustments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const numerical_adjustments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"numerical_adjustments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /paramedic_levels(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paramedic_levels_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"paramedic_levels"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /permission_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const permission_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"permission_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /preferred_areas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preferred_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_areas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /preferred_ccc_areas/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preferred_ccc_area_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_ccc_areas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preferred_provider_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:preferred_provider_id/adhoc_adjustment_fees(.:format)
 * @param {any} preferred_provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preferred_provider_adhoc_adjustment_fees_path = __jsr.r({"preferred_provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"preferred_provider_id"],[2,[7,"/"],[2,[6,"adhoc_adjustment_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /preferred_providers/:preferred_provider_id/student_add_on_charges(.:format)
 * @param {any} preferred_provider_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preferred_provider_student_add_on_charges_path = __jsr.r({"preferred_provider_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"preferred_providers"],[2,[7,"/"],[2,[3,"preferred_provider_id"],[2,[7,"/"],[2,[6,"student_add_on_charges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shift_batch/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_ccc_shift_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_shift_batch"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/previous_shifts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const previous_shifts_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"previous_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /privacy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /resources/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resources/timesheets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_timesheet_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /resources/timesheets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provider_timesheet_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shifts/:id/publish_to_candidates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_to_candidates_ccc_shift_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_shifts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish_to_candidates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /purchase_orders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const purchase_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"purchase_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rate_card_collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rate_card_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rate_card_collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ready(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ready"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /approvals/:approval_id/reject(.:format)
 * @param {any} approval_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reject_approval_path = __jsr.r({"approval_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"approvals"],[2,[7,"/"],[2,[3,"approval_id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /report_definitions/:report_definition_id/reports(.:format)
 * @param {any} report_definition_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_definition_reports_path = __jsr.r({"report_definition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report_definitions"],[2,[7,"/"],[2,[3,"report_definition_id"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report_definitions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_definitions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report_definitions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /active_storage/attachments/:id/representation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const representation_active_storage_attachment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"representation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/resend_confirmation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_confirmation_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/resend_unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resend_unlock_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resend_unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /filters/:search_entity(.:format)
 * @param {any} search_entity
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_filter_path = __jsr.r({"search_entity":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"filters"],[2,[7,"/"],[2,[3,"search_entity"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reset_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /shifts/rosters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rosters_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[6,"rosters"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/send_password_reset(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_password_reset_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_password_reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/setting(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"setting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id/setting(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"setting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/setting_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_update_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"setting_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id/setting_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setting_update_ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"setting_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shifts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shift_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /shift_batch(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shift_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shift_batch"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/shift_lock_release_edit(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shift_lock_release_edit_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"shift_lock_release_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/shift_lock_release_show(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shift_lock_release_show_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"shift_lock_release_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/shift_lock_release_update(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shift_lock_release_update_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"shift_lock_release_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shifts"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /invoices/:id/show_fees(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_fees_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /stale(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stale_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stale"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shifts/:id/summary(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const summary_shift_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shifts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"summary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /telephony_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const telephony_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"telephony_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /telephony_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const telephony_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"telephony_reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/tiered_publication_edit(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiered_publication_edit_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"tiered_publication_edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/tiered_publication_show(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiered_publication_show_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"tiered_publication_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /clients/:client_id/global_settings/tiered_publication_update(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tiered_publication_update_client_global_settings_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"global_settings"],[2,[7,"/"],[2,[6,"tiered_publication_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /timesheets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const timesheet_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /timesheets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const timesheets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"timesheets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /two_factor_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const two_factor_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"two_factor_settings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /candidates/:candidate_id/vacancies/:id/unassign(.:format)
 * @param {any} candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unassign_candidate_vacancy_path = __jsr.r({"candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"candidate_id"],[2,[7,"/"],[2,[6,"vacancies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unassign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:ccc_candidate_id/ccc_vacancies/:id/unassign(.:format)
 * @param {any} ccc_candidate_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unassign_ccc_candidate_ccc_vacancy_path = __jsr.r({"ccc_candidate_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"ccc_candidate_id"],[2,[7,"/"],[2,[6,"ccc_vacancies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unassign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/upcoming_shifts(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upcoming_shifts_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upcoming_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /candidates/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ccc_candidates/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_ccc_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ccc_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /uploads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"uploads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ccc_shift_batch/upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_ccc_shift_batch_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ccc_shift_batch"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uploads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_guides/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_guide_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"user_guides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user_guides(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_guides_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user_guides"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:user_id/permissions(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_permission_users_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"permissions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/saml/auth(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_sso_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"saml"],[2,[7,"/"],[2,[6,"auth"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /vehicle_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vehicle_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"vehicle_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /warnings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const warnings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"warnings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /warnings/acknowledge(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const warnings_acknowledge_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"warnings"],[2,[7,"/"],[2,[6,"acknowledge"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widget_preferences/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widget_preference_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"widget_preferences"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/bank_unfilled_ccc_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_bank_unfilled_ccc_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"bank_unfilled_ccc_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/bank_unfilled_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_bank_unfilled_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"bank_unfilled_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/ccc_compliance_warnings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_ccc_compliance_warnings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"ccc_compliance_warnings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/ccc_purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_ccc_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"ccc_purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/compliance_warnings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_compliance_warnings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"compliance_warnings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/filled_ccc_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_filled_ccc_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"filled_ccc_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/filled_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_filled_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"filled_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/purchase_orders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_purchase_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"purchase_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/resource_unfilled_ccc_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_resource_unfilled_ccc_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"resource_unfilled_ccc_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/resource_unfilled_shifts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_resource_unfilled_shifts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"resource_unfilled_shifts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /widgets/timesheets_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const widgets_timesheets_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"widgets"],[2,[7,"/"],[2,[6,"timesheets_status"],[1,[2,[8,"."],[3,"format"]]]]]]]);


