import consumer from "./consumer";

$(document).on("page:show remote:load attachment:load", () => {
  $(".virus-unknown").each(function() {
    const $attachment = $(this).closest(".attachment");

    consumer.subscriptions.create({
      channel: "BlobChannel",
      checksum: $attachment.data("checksum")
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
        // Trigger a request to reload the attachment view, because it may
        // already have been scanned.
        $.ajax({ url: $attachment.data("path"), dataType: "script" });
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        // Trigger a request to reload the attachment view:
        $.ajax({ url: $attachment.data("path"), dataType: "script" });
      }
    });
  });
});
