/* eslint-disable import/first */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";

import "jquery";
import {} from "jquery-ujs";

require("turbolinks").start();

// https://stackoverflow.com/a/50009155
import modernizr from "modernizr";
window.Modernizr = modernizr;

// import all images
require.context("../images", true);

// stylesheets
import "../stylesheets/application.scss.erb";
// actioncable channels
import "../javascripts/channels/index.js";
// custom coffeescript
import "../javascripts/index.coffee.erb";
/* eslint-enable import/first */
