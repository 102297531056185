loadWidget = ($container) ->
  href = $container.data("widget-path")
  $spinner = $container.closest(".panel-body").find(".fa-spinner")
  $.ajax
    url: href,
    beforeSend: ->
      $container.empty()
      $spinner.removeClass("hidden")
    success: (response) ->
      $spinner.addClass("hidden")
      $container.html(response).trigger("remote:load")

$(document).on "page:show", ->
  $(".widgets-container").trigger("widgets:load")

  $(".widget-tabbed .nav-tabs").on "click", "li a", ->
    tab = $(@).attr("href")
    $container = $("#{tab}.widget-container")
    # avoid re-loading if previously already loaded
    if $container.is(":empty")
      loadWidget $container

$(document).on "widgets:load", ->
  $(".widget-container.active:empty").each ->
    loadWidget($(@))
