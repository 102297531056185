getTemplate = (id) ->
  if id != "0"
    $.ajax "/memo_templates/#{id}"
  else
    $("#communication_subject, #communication_body").val("")

showCandidateFields = ($role) ->
  roles = $role.val()
  hideCandidateFields = !roles? || "CANDIDATE" not in roles
  $(".communication-candidate-row").toggleClass "hidden", hideCandidateFields

$(document).on "page:show remote:load", ->

  $(".js-delete-communication").on "click", ->
    $("#modal-delete-communication").modal("show")

  $(".js-template-picker").on "change", ->
    getTemplate $(@).find("option:selected").val()

  showCandidateFields $("select#communication_user_roles")

  $("select#communication_user_roles").on "change", ->
    showCandidateFields $(@)
