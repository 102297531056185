# date transfer format in jqueryui syntax
xformat = "dd M yy"

$(document).on "page:show remote:load:datepicker remote:load", ->

  $("input.datepicker:not([readonly])").each ->
    $el = $(@)
    # Abort if the datepicker is in a disabled fieldset
    return if $el.parents("fieldset[disabled]").length > 0

    # Return if native date selection is supported
    return if $el.prop('type') == 'date'

    $el.datepicker(
      dateFormat: xformat,
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: $el.data("min-date"),
      maxDate: $el.data("max-date"),
      defaultDate: $el.data("default-date"),
      yearRange: $el.data("year-range"),
      changeMonth: true,
      changeYear: true,
      beforeShow: (el) -> return !$(el).prop("readonly") # Javascript can change the readonly status
    ).prev(".input-group-addon :not(.check-box-input-group-addon)").click ->
      $(this).siblings("input.datepicker").datepicker "show" unless $(this).next().attr("disabled") || $(this).next().attr("readonly")

  # Restrict to a range if classed as from-to dates
  $(".datepicker-from").datepicker "option", "onClose", (selectedDate)->
    $(this).closest(".form-group").next().find(".datepicker-to").datepicker "option", "minDate", selectedDate
  $(".datepicker-to").datepicker "option", "onClose", (selectedDate)->
    $(this).closest(".form-group").prev().find(".datepicker-from").datepicker "option", "maxDate", selectedDate

  $("input.timepicker:not([readonly])").each ->

    # Return if native time selection is supported
    return if $(@).prop('type') == 'time'

    stepMinute = $(@).data("step-minute") || 15 # default to 15 minute intervals
    $(@).timepicker({
      stepMinute: stepMinute,
      controlType: 'select',
      oneLine: true,
      hourMax: $(@).data("hour-max") || 23,
      timeOnlyTitle: $(@).data('time-title'),
      timeText: $(@).data("time-text"),
      showButtonPanel: $(@).data("show-buttons")
    }).siblings(".input-group-addon :not(.check-box-input-group-addon)").click ->
      $(this).siblings("input.timepicker").timepicker "show"

  $("input.datetimepicker:not([readonly])").each ->
    $el = $(@)
    # Abort if the datetimepicker is in a disabled fieldset
    return if $el.parents("fieldset[disabled]").length > 0

    $el.datetimepicker({
      dateFormat: xformat,
      showOtherMonths: true,
      selectOtherMonths: true,
      minDate: $el.data("min-date"),
      maxDate: $el.data("max-date"),
      changeMonth: true,
      changeYear: true,
      controlType: 'select',
      oneLine: true
    })
