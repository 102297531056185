loadProjectedCosts = ($container) ->
  $.rails.throb on, $container
  $container.load $container.data("path")

$(document).on "page:show", ->
  $("#projected-link").on "click", (ev) ->
    ev.preventDefault()
    $(@).hide()
    $(".projected-cost-container").each ->
      loadProjectedCosts $(@)
