loadUserPermissions = ($container) ->
  # avoid re-loading if previously already loaded
  if $container.find(".fa-spinner").length
    $container.load $container.data("user-permissions-path")

$(document).on "page:show", ->
  $(".permission-tabbed").each ->
    loadUserPermissions $(@).find(".permission-container.active")

  $(".permission-tabbed .nav-tabs").on "click", "li a", ->
    tab = $(@).attr("href")
    loadUserPermissions $("#{tab}.permission-container")

# Add/remove permisison upon checking/deselecting the checkboxes.
  $("#user-permissions").on "change", ".edit_permission_user input[type='checkbox']", ->
    $(@).closest("form").each ->
      $(@).submit()
