gradeFields = ->
  $("form").find("select#ccc_candidate_grade_id")

setGradeFieldOptions = ->
  $(gradeFields()).find("option").addClass("hidden")
  if ids = $("select#ccc_candidate_department_id").val()
    $(gradeFields()).find("option[data-dept-id='#{id}']").removeClass("hidden") for id in ids

$(document).on "page:show remote:load", ->
  setGradeFieldOptions() # set on load for render purposes

  $("form #ccc_candidate_department_id").on "change", ->
    setGradeFieldOptions()

  $(".ccc_candidate_ccc_candidate_shift_settings_wday input:checkbox").on "change", ->
    $(@).closest(".ccc_candidate_ccc_candidate_shift_settings_wday").find("input[name*='[_destroy]']").val !$(@).is(":checked")
