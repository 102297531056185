get_content = ->
  $elem = $(@)
  $elem.data("content") || loadContent($elem)

loadContent = ($elem) ->
  div_id = "tmp-id-#{$.now()}"
  $.ajax
    url: $elem.data("ajaxload")
    success: (response) -> $("##{div_id}").html(response)

  '<div id="'+div_id+'" class="big-popover"><div class="spinner-container"><i class="fa fa-spinner fa-spin fa-pulse fa-fw fa-2x"></i></div></div>'

$(document).on "page:show remote:load", ->
  $("[rel=popover]").popover
    html: true,
    trigger: "hover",
    santize: false,
    content: get_content
