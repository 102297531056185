$(document).on "page:show", ->
  $('.js-worker-search').on "click", ->
    $container = $(@).parents(".crew-requirement")
    $container.find("form").submit()
    $container.find(".no-candidate").hide()
    $container.find(".worker-search").show()

  $(".worker-search form").on "submit", (ev)->
    ev.preventDefault()
    ev.stopPropagation()
    $container = $(@).parents(".worker-search")
    $responseContainer = $container.find(".load-results")
    $throbber = $container.find(".fa-spin-container")
    $btn = $(@).find(".btn")
    enabledBtnText = $btn.text()
    $.ajax
      url: $(@).attr("action")
      data:
        name_q: $(@).find("input#name_q").val()
        paramedic_levels_q: $(@).find("select#paramedic_levels_q").val()
        grade_ids_q: $(@).find("select#grade_ids_q").val()
        provider_ids_q: $(@).find("select#provider_ids_q").val()
      beforeSend: ->
        $throbber.removeClass("hidden")
        $responseContainer.empty()
        $btn.attr("disabled", true).text($btn.data("disable-with"))
      success: (response) ->
        $throbber.addClass("hidden")
        $responseContainer.html(response).trigger("remote:load")
        $btn.attr("disabled", false).text(enabledBtnText)
