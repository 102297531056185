$(document).on "page:show remote:load", ->

  $("form .numerical-adjustment-form-container").each ->
    $container = $(@)

    $container.find("select[name*='provider']").on "change", ->
      $invoiceField = $container.find("input[name*='[invoice_id]']")
      $invoiceField.select2("val", "")
      $invoiceField.parents(".row.form-group").toggleClass "hidden", $(@).val().length == 0

    $container.find("input[name*='[invoice_id]']").select2
      allowClear: true,
      initSelection: (el, callback) ->
        if id = el.val()
          url = Routes.invoice_path id, format: "json"
          $.ajax(url).done callback
      ajax:
        url: Routes.invoices_path(format: "json")
        data: (term) ->
          q: {reference_cont: term, provider_id_eq: $container.find("select[name*='provider']").val()}
        results: (data) -> results: data
      formatResult: (item) -> item.name
      formatSelection: (item) -> item.name
      formatNoMatches: "No matches found"
