$(document).on("page:show", function() {
  $(".js-edit-preferences").click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    showBulkEdit();
  });

  $(".js-cancel").click(function() {
    hideBulkEdit();
  });
});

function hideBulkEdit() {
  $(".js-edit-preferences").parent().removeClass("active");
  $("#preferences-bulk-edit-footer").removeClass("active");
  $("#preferences-bulk-edit-footer").animate({ "margin-bottom": "-100px" }, 300);
  $(".candidate-calendar .selected").removeClass("selected");
  $("#candidate-global-preferences").hide();
  $(".candidate-calendar-shifts").show();
  $(".js-edit-preferences").removeClass("candidate-preferences");
}

function showBulkEdit() {
  $(".js-edit-preferences").parent().addClass("active");
  $("#preferences-bulk-edit-footer").animate({ "margin-bottom": "50px" }, 300);
  $("#preferences-bulk-edit-footer").addClass("active");
  $("#candidate-global-preferences").show();
  $(".candidate-calendar-shifts").hide();
  $(".js-edit-preferences").addClass("candidate-preferences");
}
