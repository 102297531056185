$(document).on "page:show", ->

  $(".invoice-runs .new-invoice-run").each ->
    $(@).load $(@).data("invoice-run-path")

  $(".new-invoice-run").on "click", ".generate-invoice-run", ->
    $.rails.throb on, $(@).closest("td")
    $(@).remove()
    $form = $("form#create-invoice-run-form")
    $form.find("input[name='week_start']").val $(@).data("week-start")
    $form.find("input[name='week_end']").val $(@).data("week-end")
    $form.find("input[name='provider_id']").val $(@).data("provider-id")
    $form.submit()

  $(".ccc-invoice-runs .new-ccc-invoice-run").each ->
    $(@).load $(@).data("ccc-invoice-run-path")

  $(".new-ccc-invoice-run").on "click", ".generate-ccc-invoice-run", ->
    $.rails.throb on, $(@).closest("td")
    $(@).remove()
    $form = $("form#create-ccc-invoice-run-form")
    $form.find("input[name='month_start']").val $(@).data("month-start")
    $form.find("input[name='month_end']").val $(@).data("month-end")
    $form.find("input[name='provider_id']").val $(@).data("provider-id")
    $form.submit()
