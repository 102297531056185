updateHours = ->
  diff = moment.utc(moment($("#shift_end_time").val(), "HH:mm").diff(moment($("#shift_start_time").val(), "HH:mm"))).format("HH:mm")
  hoursMinutes = diff.split(/[.:]/)
  hours = parseInt(hoursMinutes[0], 10)
  minutes = if hoursMinutes[1] then parseInt(hoursMinutes[1], 10) else 0
  $('#shift_unit_hours').val (hours + minutes / 60).toFixed(2)

  n_workers = 0
  n_workers += if $('#shift_vacancy_1').val().length then 1 else 0
  n_workers += if $('#shift_vacancy_2').val().length then 1 else 0
  $('#shift_staff_hours').val ($('#shift_unit_hours').val() * n_workers).toFixed(2)

setVacancyGradeFieldOptions = (vehicle_type_id) ->
  $.ajax
    url: Routes.client_vehicle_type_paramedic_levels_path($("#shift_client_id").val(), vehicle_type_id)
    dataType: "script"
  success: (response) ->
    updateHours()

selected_shift_ids = ->
  $selected = $(".shifts-container .shift .toggle:checked")
  $selected.map(-> $(@).val()).get().join(",")

$(document).on "page:show", ->
  $("form .shift-form-container").on "change", ".js-tiered-publication", ->
    $(".shift-form-container #tiered_publication").toggle $(@).val() == "SHORT"

  $("#shift_vehicle_type_id").on "change", ->
    setVacancyGradeFieldOptions $(@).val()

  $("#shift_start_time, #shift_end_time, #shift_vacancy_1, #shift_vacancy_2").on "change", ->
    updateHours()

  $(".shifts-container").on "change", ".toggle", ->
    isChecked = $(@).is(":checked")
    $toggleAll = $(".shifts-container input#shifts-toggle")
    $toggles = $(".shifts-container .shift .toggle")

    if $(@).is($toggleAll)
      $toggles.prop("checked", isChecked) # select all or none
    else
      # Have the all/none toggle be reflective of the toggles checked.
      $toggleAll.prop("checked", !$toggles.is(":not(:checked)"))

    # Allow actions to be performed if any shift is selected.
    if $toggles.is(":checked") then $.rails.showBulkEdit() else $.rails.hideBulkEdit($(@))

  $("form#new-shift-batch-form").on "click", "input[type=submit]", (ev) ->
    method = $(@).data("method")
    $(ev.delegateTarget).each ->
      $(@).find("input[name=method]").val(method)
      $(@).find("input[name=ids]").val selected_shift_ids()

  $("#bulk-edit-footer").on "click", ".js-cancel", ->
    $.rails.hideBulkEdit $(".shifts-container")

  $(".shifts .js-table-toggle-actions .actions").each ->
    $container = $(@)
    $container.load $container.data("path"), ->
      $container.trigger("remote:load")

$(document).on "remote:load", ->
  $(".js-assign-wtd-candidate-form").on "click", ->
    id = $(@).data("candidate-id")
    $("form#assign_wtd_candidate_#{id}").submit()

  $(".js-show-vacancy-comments").on "click", ->
    $(@).hide()
    id = $(@).data("candidate-id")
    $("#assign_wtd_candidate_#{id}").show()
    $(".js-assign-wtd-candidate-form[data-candidate-id='#{id}']").removeClass("hidden")
