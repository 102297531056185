acknowledgeNotification = (notification_id)->
  $.ajax
    type: "patch"
    url: Routes.acknowledge_notification_path(notification_id)

showNotificationDetails = (notification_id)->
  $.ajax Routes.notification_path(notification_id)

$(document).on "page:show remote:load", ->
  $(".js-show-notification-details").on "click", ->
    showNotificationDetails $(@).data("notification-id")

$(document).on "page:show remote:load shown.bs.modal", ->
  $(".js-acknowledge-notification").on "click", ->
    acknowledgeNotification $(@).data("notification-id")
    $("#modal-notification-details").modal("hide")
