getPossibleShifts = ->
  $form = $("form.ccc_timesheet_form")
  date = $form.find("#ccc_timesheet_incurred_on").val()
  clientId = $form.find("#ccc_timesheet_client_id").val()
  candidateId = $form.find("#ccc_timesheet_ccc_candidate_id").val()
  cccTimesheetId = $form.find("input#ccc_timesheet_id").val()
  if date and clientId and candidateId
    $.ajax
      url: Routes.ccc_candidate_ccc_vacancies_path(candidateId)
      data: {"date": date, "client_id": clientId, "ccc_timesheet_id": cccTimesheetId}
      dataType: "script"

selected_ccc_timesheet_ids = ->
  $selected = $(".ccc-timesheets-container .ccc-timesheet .toggle:checked")
  $selected.map(-> $(@).val()).get().join(",")

$(document).on "page:show remote:load", ->
  getPossibleShifts() # on load for render purposes

  $("form.ccc_timesheet_form").each ->
    $(@).find("#ccc_timesheet_incurred_on, #ccc_timesheet_ccc_candidate_id").on "change", ->
      getPossibleShifts()

  $(".ccc-timesheets-container").on "change", ".toggle", ->
    isChecked = $(@).is(":checked")
    $toggleAll = $(".ccc-timesheets-container input#ccc-timesheets-toggle")
    $toggles = $(".ccc-timesheets-container .ccc-timesheet .toggle")

    if $(@).is($toggleAll)
      $toggles.prop("checked", isChecked) # select all or none
    else
      # Have the all/none toggle be reflective of the toggles checked.
      $toggleAll.prop("checked", !$toggles.is(":not(:checked)"))

    # Allow actions to be performed if any timesheet is selected.
    if $toggles.is(":checked") then $.rails.showBulkEdit() else $.rails.hideBulkEdit($(@))

  $("#bulk-edit-footer").on "click", ".js-cancel", ->
    $.rails.hideBulkEdit $(".ccc-timesheets-container")

  $("form#new-ccc-timesheet-batch-form").on "click", "input[type=submit]", (ev) ->
    method = $(@).data("method")
    $(ev.delegateTarget).each ->
      $(@).find("input[name=method]").val(method)
      $(@).find("input[name=ids]").val selected_ccc_timesheet_ids()
