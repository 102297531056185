# ------------------------------------------------------------------------------
# Managed via yarn
# ------------------------------------------------------------------------------
import "bootstrap-sass"

import "font-awesome-sass/assets/stylesheets/_font-awesome.scss";

import alertify from "alertifyjs"
window.alertify = alertify;
window.alertify.defaults.basic = true;
import "alertifyjs/build/css/alertify.css"
import "alertifyjs/build/css/themes/bootstrap.css"

# js-routes
import * as Routes from "./routes.js.erb";
window.Routes = Routes;

import "daterangepicker/daterangepicker"
import "daterangepicker/daterangepicker.css"

import "bootstrap-datepicker/dist/js/bootstrap-datepicker"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css"

import "jquery-ui/ui/core";
import "jquery-ui/ui/i18n/datepicker-en-GB";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/all.css"; # jQuery-UI styling

import "jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.js";
import "jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.css";

import "clockpicker/dist/bootstrap-clockpicker"
import "clockpicker/dist/bootstrap-clockpicker.css"

import moment from "moment"
window.moment = moment;
import "moment/dist/locale/en-gb"

import "select2-v3/select2.js"
import "select2-v3/select2.css" # styling
import "select2-v3/select2-bootstrap.css"

import "@nathanvda/cocoon/cocoon"

import "jquery-hoverintent/jquery.hoverIntent"

# ------------------------------------------------------------------------------
# imports from gems
# ------------------------------------------------------------------------------
# remotipart
import "/home/waverise/apps/bluelights/uat/shared/bundle/ruby/2.7.0/gems/remotipart-1.4.4/vendor/assets/javascripts/jquery.iframe-transport.js"
import "/home/waverise/apps/bluelights/uat/shared/bundle/ruby/2.7.0/gems/remotipart-1.4.4/vendor/assets/javascripts/jquery.remotipart.js"

# ------------------------------------------------------------------------------
# xenon theme imports
# ------------------------------------------------------------------------------
import "javascripts/themes/zabuto_calendar.min"
import "javascripts/themes/TweenMax.min"
import "javascripts/themes/xenon-api"
import "javascripts/themes/xenon-main"

# ------------------------------------------------------------------------------
# Our own javascript
# ------------------------------------------------------------------------------
import "javascripts/global"
# import the coffeescript and javascript in each directory

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_common.js";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_forms.js";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_tooltips.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_navs.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_pagination.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_forms.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_before_cache.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_before_render.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_images.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_datepicker.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_tables.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_widgets.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/components/_popover.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/candidate.js";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/ccc_numerical_adjustments.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/audits.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/users.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/notifications.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/candidates.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/shifty.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/cad_reports.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/ccc_shifts.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/crew_sheets.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/ccc_timesheets.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/shifts.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/rotors.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/ccc_candidates.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/communications.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/numerical_adjustments.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/purchase_orders.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/timesheets.coffee";

import "/home/waverise/apps/bluelights/uat/releases/20240320092847/app/packs/javascripts/modules/invoice_runs.coffee";

